import React, {useState, useEffect} from "react";
import { graphql } from "gatsby";
import moment from "moment";
import _ from 'lodash';
import { withPrefix } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { navigate } from "gatsby"
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import {findIconDefinition } from '@fortawesome/fontawesome-svg-core'
const isBrowser = typeof document !== "undefined"



export const initialiseAccessible = ({path='', accessibleFolder=''}) => {
  //console.log("A",path,accessibleFolder)
  return path.startsWith( `/${accessibleFolder}/` );
}

export const navigateCorrectPage = ({ accessible, path='', accessibleFolder='', pages=[]}) => {
  //console.log("C",accessible,path,accessibleFolder)
  if ((path.length>0)&&(pages.length>0)) {
    if (accessible && !path.includes(accessibleFolder)) {
      let a = path.startsWith('/') ? `/${accessibleFolder}${path}` : `/${accessibleFolder}/${path}`;
      if (_.includes( pages, a) ) {
        navigate(a)
        }
    } else if (!accessible && path.includes(accessibleFolder)) {
      let prefix = `/${accessibleFolder}/`;
      let a = path.startsWith( prefix ) ? "/"+path.slice( prefix.length ) : path;
      if (_.includes( pages, a) ) {
        navigate(a);
      }
    }
  }
}


export const accessibleHandleClick = ({ accessible, path='', accessibleFolder='', pages =[] }) => {
  //console.log("B",accessible, path,accessibleFolder);
  navigateCorrectPage({ accessible: !accessible, path: path , accessibleFolder: accessibleFolder, pages: pages });
}


export const AccessibleButton  = ({accessible, path, accessibleFolder, pages, handleClick=accessibleHandleClick }) => {
  return(
    <button onClick={()=>handleClick({ accessible: accessible, path: path , accessibleFolder: accessibleFolder, pages: pages })} >
      { accessible ? <FontAwesomeIcon size='lg'   icon={solid('image')}/> :  <FontAwesomeIcon size='lg'   icon={regular('image')} /> }
      { accessible ? "Normal Page" :  "Accessible Page"}
    </button>
  )
}
//
